import $ from'jquery';
import 'popper.js';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/fontawesome';

import './ajax_csrf';

window.$ = $;
